export function calculateIncomePerWeekday(data) {
    const arr = [
      {
        name: 'Mon',
        sold: 0,
        income: 0,
      },
      {
        name: 'Tue',
        sold: 0,
        income: 0,
      },
      {
        name: 'Wed',
        sold: 0,
        income: 0,
      },
      {
        name: 'Thu',
        sold: 0,
        income: 0,
      },
      {
        name: 'Fri',
        sold: 0,
        income: 0,
      },
      {
        name: 'Sat',
        sold: 0,
        income: 0,
      },
      {
        name: 'Sun',
        sold: 0,
        income: 0,
      },
    ]

    data.forEach((i) => {
      let key = i['date'].split(' ')[0]
      let obj = arr.find((i) => i.name === key)
      obj.sold += 1
      obj.income += i.price
    })

    return arr
  }