import './ChartSection.css'
import { BarChartComponent } from './BarChart';
import { useEffect } from 'react';
import { calculateIncomePerWeekday } from '../../../utils/profitCalc'

export function ChartSection({ticketData}) {
    const barChartData = calculateIncomePerWeekday(ticketData)

    // useEffect(() => {
    //   console.log(ticketData)
    // })
    
    return (
      <span id="chart-section-wrapper">
          <span>
              <BarChartComponent data={barChartData} />
          </span>
      </span>
    )
}